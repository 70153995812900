import {LineItemModel} from './LineItem.model';
import {AppliedCouponModel} from './AppliedCoupon.model';
import {ItemTypePreset} from './ItemType.model';
import {PriceSummaryModel} from './PriceSummary.model';
import {GiftCardModel} from './GiftCard.model';
import {AdditionalFeeModel} from './AdditionalFee.model';
import {CustomFieldModel} from './CustomField.model';
import {BuyerInfoModel} from './BuyerInfo.model';
import {AddressWithContactModel} from './AddressWithContact.model';
import {ShippingOptionModel} from './ShippingOption.model';
import {ErrorsModel} from './Errors.model';
import {PriceModel} from './Price.model';
import {CheckoutFragment, ShippingOptionFragment} from '../../gql/graphql';
import {Checkout} from '../../types/app.types';
import {isCheckoutWithEnrichedItems, isCheckoutWithViolations} from '../utils/types.util';
import {ViolationModel} from './Violation.model';

export class CheckoutModel {
  public id: string;
  public cartId?: string;
  public hasDigitalItems: boolean;
  public buyerNote?: string;
  public buyerLanguage?: string;
  public channelType?: string;
  public siteLanguage?: string;
  public lineItems: LineItemModel[];
  public numberOfItems: number;
  public appliedCoupon?: AppliedCouponModel;
  public hasShippableItems: boolean;
  public ecomId?: string;
  public hasItemsWithLongPrice: boolean;
  public hasSubscriptionItems: boolean;
  public itemTypes: Set<ItemTypePreset>;
  public priceSummary: PriceSummaryModel;
  public payNowTotalAfterGiftCard: PriceModel;
  public giftCard?: GiftCardModel;
  public payLater?: PriceSummaryModel;
  public additionalFees: AdditionalFeeModel[];
  public customField?: CustomFieldModel;
  public buyerInfo: BuyerInfoModel;
  public billingInfo?: AddressWithContactModel;
  public shippingDestination?: AddressWithContactModel;
  public selectedShippingOption?: ShippingOptionModel;
  public shippingOptions: ShippingOptionModel[];
  public pickupOptions: ShippingOptionModel[];
  public currency?: string;
  public errors: ErrorsModel;
  public violations: ViolationModel[];

  constructor(checkout: Checkout, useEnrichedItems?: boolean) {
    this.id = checkout.id!;
    this.cartId = mapNullToUndefined(checkout.cartId);
    this.buyerLanguage = mapNullToUndefined(checkout.buyerLanguage);
    this.channelType = mapNullToUndefined(checkout.channelType);
    this.siteLanguage = mapNullToUndefined(checkout.siteLanguage);
    this.buyerNote = mapNullToUndefined(checkout.buyerNote);
    this.lineItems = (checkout.lineItems ?? []).map(
      (item) =>
        new LineItemModel(
          item!,
          checkout.membershipOptions!,
          useEnrichedItems && isCheckoutWithEnrichedItems(checkout)
            ? checkout.externalEnrichedLineItems?.enrichedLineItems
            : undefined
        )
    );
    this.ecomId = mapNullToUndefined(checkout.ecomId);
    this.hasDigitalItems = this.lineItems.some((lineItem) => lineItem.itemType.preset === ItemTypePreset.DIGITAL);
    this.numberOfItems = this.lineItems.reduce((acc: number, {quantity}: LineItemModel) => acc + quantity, 0);
    this.appliedCoupon = AppliedCouponModel.fromDTO(mapNullToUndefined(checkout.appliedDiscounts));
    this.hasShippableItems = this.lineItems.some(
      ({itemType}) => itemType.preset === ItemTypePreset.PHYSICAL || itemType.preset === ItemTypePreset.UNRECOGNISED
    );
    this.hasItemsWithLongPrice = this.lineItems.some((lineItem) => lineItem.prices.isLongPrice);
    this.hasSubscriptionItems = this.lineItems.some((lineItem) => lineItem.subscription);
    this.itemTypes = new Set(
      this.lineItems.filter(({itemType}) => itemType.preset).map(({itemType}) => itemType.preset!)
    );
    this.priceSummary = new PriceSummaryModel(mapNullToUndefined(checkout.priceSummary));
    this.payNowTotalAfterGiftCard = new PriceModel(mapNullToUndefined(checkout.payNowTotalAfterGiftCard));
    this.giftCard = checkout.giftCard ? new GiftCardModel(checkout.giftCard) : undefined;
    this.payLater = checkout.payLater ? new PriceSummaryModel(checkout.payLater) : undefined;
    this.additionalFees = (checkout.additionalFees ?? [])
      .filter((fee) => !!fee)
      .map((additionalFee) => new AdditionalFeeModel(additionalFee!));
    this.customField = checkout.customFields?.[0] ? new CustomFieldModel(checkout.customFields?.[0]) : undefined;
    this.buyerInfo = new BuyerInfoModel(mapNullToUndefined(checkout.buyerInfo));
    this.billingInfo = checkout.billingInfo ? new AddressWithContactModel(checkout.billingInfo) : undefined;
    this.shippingDestination = checkout.shippingInfo?.shippingDestination
      ? new AddressWithContactModel(checkout.shippingInfo?.shippingDestination)
      : undefined;
    this.selectedShippingOption = checkout.shippingInfo?.selectedCarrierServiceOption
      ? new ShippingOptionModel(checkout.shippingInfo?.selectedCarrierServiceOption)
      : undefined;

    const allOptions = getFlattenedShippingOptions(checkout);
    this.shippingOptions = getOptionModels(allOptions.filter((option) => !isPickupOption(option)));
    this.pickupOptions = getOptionModels(allOptions.filter(isPickupOption));
    this.currency = mapNullToUndefined(checkout.currency);
    this.errors = new ErrorsModel(this.lineItems, mapNullToUndefined(checkout.calculationErrors));
    this.violations = mapViolations(checkout);
  }
}

function mapViolations(checkout: Checkout): ViolationModel[] {
  return isCheckoutWithViolations(checkout)
    ? (checkout.violations ?? /* istanbul ignore next */ [])
        .filter((violation) => Boolean(violation))
        .map((violation) => new ViolationModel(violation!))
    : [];
}

function mapNullToUndefined<T>(item: T | null) {
  return item ?? undefined;
}

function isPickupOption({logistics}: ShippingOptionFragment): boolean {
  return !!logistics?.pickupDetails;
}

function getFlattenedShippingOptions(checkout: CheckoutFragment): ShippingOptionFragment[] {
  return (
    checkout.shippingInfo?.carrierServiceOptions?.flatMap((carrierServiceOption) =>
      carrierServiceOption?.shippingOptions
        ? (carrierServiceOption.shippingOptions as ShippingOptionFragment[])
        : /* istanbul ignore next */ []
    ) ?? []
  );
}

function getOptionModels(options: ShippingOptionFragment[]): ShippingOptionModel[] {
  const optionsByCode = options.reduce<{[code: string]: ShippingOptionFragment[]}>(
    (acc, option) => ({...acc, [scheduledDeliveryKey(option)]: [...(acc[scheduledDeliveryKey(option)] ?? []), option]}),
    {}
  );

  return sortedShippingOptions(
    Object.keys(optionsByCode).map((code) => {
      const [option] = optionsByCode[code];
      if (optionsByCode[code].length === 1) {
        return new ShippingOptionModel(option);
      }
      return new ShippingOptionModel(option, optionsByCode[code]);
    })
  );
}

const scheduledDeliveryKey = (option: ShippingOptionFragment) =>
  `${option.title!}-${option.cost?.price?.amount ?? /* istanbul ignore next */ ''}`;

function sortedShippingOptions(options: ShippingOptionModel[]): ShippingOptionModel[] {
  return options.sort((a, b) => {
    return a.price.amount - b.price.amount;
  });
}
